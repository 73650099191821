import { useTranslation } from "next-i18next";
import { Box, Button, Center, Overlay, SimpleGrid, rem } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { RouteCard } from "./RouteCard";
import { IRoute } from "@/types";
import { IconArrowDown } from "@tabler/icons-react";
import {
  formatPrice,
  getCurrency,
  getCurrentRates,
  getHandlingFee,
  useDeviceQuery,
} from "@/utils";
import { FALLBACK_RATES, SEARCH_DEVICE } from "@/constants";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import RouteCardJsonLd from "@/components/JsonLd/RouteCard";

export default function PopularTickets({
  routes,
  hasImg,
}: {
  routes: IRoute[];
  hasImg?: boolean;
}): JSX.Element {
  const { t, i18n } = useTranslation();
  const handlingFee = getHandlingFee(i18n.language);
  const [isMobile] = useDeviceQuery();
  const router = useRouter();
  const { query } = router;

  const [rates, setRates] = useState<{ [key: string]: number }>({});
  const [expanded, setExpanded] = useState<boolean>(false);
  const [currency, setCurrency] = useState<{
    currency: string;
    symbol?: string;
  }>({
    currency: "",
    symbol: "",
  });

  useEffect(() => {
    const selectedCurrency = getCurrency();
    setCurrency(selectedCurrency);
  }, []);

  useEffect(() => {
    const ratesFromSession = getCurrentRates();
    if (Object.keys(ratesFromSession).length > 0) {
      setRates(ratesFromSession);
    } else {
      setRates(FALLBACK_RATES);
    }
  }, []);

  const spRoutes = useMemo(() => {
    return expanded ? routes : routes.slice(0, 6);
  }, [expanded, routes]);

  const pcRoutes = useMemo(() => {
    return expanded ? routes : routes.slice(0, 12);
  }, [expanded, routes]);

  const searchUrl = (route: any) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_NAVITIME_SUBMIT_URL}/${i18n.language}/booking/jr/search`;
    const date = dayjs(new Date()).add(5, "days").format("YYYYMMDD");

    const cid = `?cid=${
      query.cid ?? (isMobile ? SEARCH_DEVICE.SP : SEARCH_DEVICE.PC)
    }`;
    const via = query.via ? `?via=${query.via}` : "";
    const depStationName = `&depStationName=${route.from?.name}`;
    const departure = `&departure=${route.from?.code}`;
    const arvStationName = `&arvStationName=${route.to?.name}`;
    const arrival = `&arrival=${route.to?.code}`;
    const searchDate = `&date=${date}`;
    const searchType = "&searchType=one-way";
    return `${
      baseUrl +
      cid +
      via +
      depStationName +
      departure +
      arvStationName +
      arrival +
      searchDate +
      searchType
    }`;
  };

  return (
    <>
      <RouteCardJsonLd
        routes={routes}
        searchUrl={searchUrl}
        currency={currency.currency}
      />
      <Box hiddenFrom="md">
        <Box pos="relative">
          {spRoutes?.map((route, index) => (
            <RouteCard
              url={searchUrl(route)}
              hasImg={hasImg}
              key={index}
              route={route}
              price={formatPrice(
                Number(route.price) + handlingFee,
                currency.currency,
                rates[currency.currency],
              )}
              currency={currency.currency}
            />
          ))}
          {routes.length > 6 && !expanded && (
            <>
              <Box
                h={hasImg ? rem(160) : rem(130)}
                pos="absolute"
                left={0}
                right={0}
                bottom={0}
                className="z-[80]"
              >
                <Overlay
                  color="#fff"
                  backgroundOpacity={0.35}
                  blur={4}
                  zIndex={80}
                />
              </Box>
              <Button
                className="z-[99] w-full rounded-md uppercase font-bold h-12 absolute bottom-8"
                onClick={() => setExpanded(true)}
              >
                {t("common:see_more")}
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box visibleFrom="md">
        {routes.length > 12 ? (
          <>
            <SimpleGrid cols={4}>
              {pcRoutes?.map((route, index) => (
                <RouteCard
                  url={searchUrl(route)}
                  hasImg={hasImg}
                  key={index}
                  route={route}
                  price={formatPrice(
                    Number(route.price) + handlingFee,
                    currency.currency,
                    rates[currency.currency],
                  )}
                  currency={currency.currency}
                />
              ))}
            </SimpleGrid>
            {!expanded && (
              <Center mt="xl">
                <Button
                  variant="outline"
                  classNames={{
                    root: `rounded-md uppercase font-bold h-12 border-2 
                              border-solid bg-white`,
                    section: "absolute right-5",
                  }}
                  rightSection={<IconArrowDown />}
                  color="orange.9"
                  w={{ base: "100%", md: rem(500) }}
                  h={rem(60)}
                  onClick={() => setExpanded(true)}
                >
                  {t("common:see_more")}
                </Button>
              </Center>
            )}
          </>
        ) : (
          <SimpleGrid cols={4}>
            {routes?.map((route, index) => (
              <RouteCard
                url={searchUrl(route)}
                hasImg={hasImg}
                key={index}
                route={route}
                price={formatPrice(
                  Number(route.price) + handlingFee,
                  currency.currency,
                  rates[currency.currency],
                )}
                currency={currency.currency}
              />
            ))}
          </SimpleGrid>
        )}
      </Box>
    </>
  );
}
