import { generateJsonLd, getAssetUrl, getHandlingFee } from "@/utils";
import { useTranslation } from "next-i18next";

export default function RouteCardJsonLd({
  routes,
  searchUrl,
  currency,
}: {
  routes: any[];
  searchUrl: (route: any) => string;
  currency: string;
}) {
  const { t, i18n } = useTranslation();
  const handlingFee = getHandlingFee(i18n.language);

  const jsonLdProducts = generateJsonLd("ItemList", i18n.language, {
    itemListElement: routes.map((route) => ({
      "@type": "Product",
      name: t("common:shinkansen_tickets_title", {
        departure: t(
          `locations:prefectures.${route.from?.prefecture}.${route.from?.name}`,
        ),
        destination: t(
          `locations:prefectures.${route.to?.prefecture}.${route.to?.name}`,
        ),
      }),
      brand: {
        "@type": "Brand",
        name: "JR",
      },
      seller: {
        "@type": "Organization",
        name: "Japan Bullet Train",
      },
      description: t("common:shinkansen_tickets_description", {
        departure: t(
          `locations:prefectures.${route.from?.prefecture}.${route.from?.name}`,
        ),
        destination: t(
          `locations:prefectures.${route.to?.prefecture}.${route.to?.name}`,
        ),
      }),
      image: getAssetUrl(route.img || ""),
      offers: {
        "@type": "Offer",
        priceCurrency: currency || "JPY",
        price: String(route.price + handlingFee) || "0",
        url: searchUrl(route),
      },
    })),
  });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonLdProducts),
      }}
    />
  );
}
